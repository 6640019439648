import {UIcon} from 'uloc-vue'

const extensions = {
  jpg: {icon: 'file-image', type: 'fa', style: 'regular'},
  pdf: {icon: 'file-pdf', type: 'fa', style: 'regular'},
  docx: {icon: 'file-word', type: 'fa', style: 'regular'},
}
export default {
  name: 'file-extension-icon',
  props: {
    extension: {
      type: String,
      required: true
    }
  },
  computed: {
    recognizedExtension () {
      return typeof extensions[this.extension] !== 'undefined'
    },
    iconProps () {
      let props = extensions[this.extension]
      return {
        name: props.icon,
        type: props.type,
        'icon-style': props.style
      }
    },
    classes () {
      let css = []
      this.recognizedExtension && css.push('has-icon')
      return css
    }
  },
  render (h) {
    let child
    if (this.recognizedExtension) {
      child = h(UIcon, {props: this.iconProps})
    } else {
      child = this.extension
    }
    return h('span', {
      staticClass: 'extensionIcon',
      class: this.classes
    }, [child])
  }
}
